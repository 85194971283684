body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Matches_cards__2re2E{
    max-width: 100%;
    /*display: inline-grid;
    column-gap: 10px;
    row-gap: 10px;*/
}

table,tr,td,th{
    border: 1px solid black;
}
.TipForm_modal__2_Dgg {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    background-color: white;
    padding: 1rem;
    text-align: center;
    width: 30rem;
    z-index: 10;
    position: fixed;
    top: 20vh;
    left: calc(50% - 15rem);
  }
.backdrop {
    position: fixed;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.75);
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
  }
.MatchCard_card__HylVU{
    border: 1px solid black;
    align-items: center;
    border-radius: 10px;
    padding: 10px;
    width: 80%;
    margin: 5px;
    /*grid-row-start: 1;
    grid-row-end: 3;*/
    
}
.MatchCard_row__S0q1a{
    display: flex;
    align-items: center;
    text-align: center;
    margin: 10 auto 5 auto;
    justify-content: center;
}

.MatchCard_flag__5kXlR{
    margin: 0 20px;
}
.MatchCard_flag__5kXlR img{
    height: 25px;
}

.MatchCard_teamA__lg7T8{
    margin-right: 5px;
}
.MatchCard_teamB__3rk5i{
    margin-left: 5px;
}

.control{
    background-color: red;
}
.action{
    background-color: aqua;
}
.Layout_main__1FJvE{
    margin: 1rem auto;
    width: 90%;
    max-width: 80rem;
}
.Rank_header__3qUhb{
    font-size: large;
}
