.card{
    border: 1px solid black;
    align-items: center;
    border-radius: 10px;
    padding: 10px;
    width: 80%;
    margin: 5px;
    /*grid-row-start: 1;
    grid-row-end: 3;*/
    
}
.row{
    display: flex;
    align-items: center;
    text-align: center;
    margin: 10 auto 5 auto;
    justify-content: center;
}

.flag{
    margin: 0 20px;
}
.flag img{
    height: 25px;
}

.teamA{
    margin-right: 5px;
}
.teamB{
    margin-left: 5px;
}
